








































































































































































































































import Vue from 'vue'
import Nav from '@/components/Nav.vue'
import { ApiResponse, Product, Rate, Structure } from '@/interfaces'
import { mapState } from 'vuex'

interface Filter {
  text: string | null
  value: string | null
}

interface SortObject {
  order: string
  prop: string
}

export default Vue.extend({
  components: {
    Nav,
  },
  data() {
    return {
      products: [],
      vatRates: [] as Rate[],
      structureVatRates: [[] as Rate[]],
      search: '',
      busy: false,
      page: '1',
      limit: 50,
      activePage: 1,
      itemsTotal: 0,
      numPages: 1,
      orderBy: null as null | string,
      order: null as null | string,
      structureId: null as number | null,
      structures: [] as Record<string, string | null>[],
      productTypes: [
        { label: 'Frais', value: 'frais' },
        { label: 'Emoluments', value: 'emolument' },
        { label: '% Acquéreur', value: 'percent_buyer' },
        { label: 'Fixe Acquéreur', value: 'fixe_buyer' },
        { label: '% Vendeur', value: 'percent_seller' },
        { label: 'Fixe Vendeur', value: 'fixe_seller' },
      ],
      productCategories: [
        { label: 'Honoraires acheteur', value: 'hono_buyer' },
        { label: 'Honoraires vendeur', value: 'hono_seller' },
        { label: 'Honoraires inventaire', value: 'hono_inventory' },
        { label: 'Expert', value: 'expert' },
        { label: 'Transport', value: 'logistic' },
        { label: 'Taxes', value: 'taxes' },
      ],
      structureFilter: [] as Filter[],
      productTypeFilter: null as string | null,
      productCategoryFilter: null as string | null,
      addons: 0,
    }
  },
  computed: {
    ...mapState(['user']),
  },
  watch: {
    user(newVal) {
      this.structures = newVal.client.structures
      this.addons = newVal.client?.addons ?? 0
    },

    products(newVal) {
      const structures = [] as Filter[]
      newVal.forEach((product: Product) => {
        if (product.structure) {
          structures.push({
            value: product.structure?.id?.toString() ?? null,
            text: product.structure?.name ?? null,
          })
          if (product.structureId && !this.structureVatRates[product.structureId]) {
            this.structureVatRates[product.structureId] = product.structure?.taxRate?.rates ?? []
          }
        }
      })

      // Filtering duplicates
      this.structureFilter = structures.reduce((unique: Filter[], o: Filter) => {
        if (!unique.some((obj: Filter) => obj.text === o.text && obj.value === o.value)) {
          unique.push(o)
        }
        return unique
      }, [])
    },
  },
  mounted() {
    if (this.user) {
      this.structures = this.user.client.structures
      this.addons = this.user.client?.addons ?? 0
    }
    if (this.$route.query.search && this.$route.query.search !== '') {
      this.search = this.$route.query.search as string
    }

    if (this.$route.query.page) {
      this.page = this.$route.query.page as string
    }

    if (this.$route.query.search) {
      this.search = this.$route.query.search as string
    }

    if (this.$route.query.orderby) {
      this.orderBy = this.$route.query.orderby as string
    }

    if (this.$route.query.order) {
      this.order = this.$route.query.order as string
    }

    if (this.$route.query.structure) {
      this.structureId = parseInt(this.$route.query.structure as string)
    }
    if (this.$route.query.productType) {
      this.productTypeFilter = this.$route.query.productType as string
    }
    if (this.$route.query.productCategory) {
      this.productCategoryFilter = this.$route.query.productCategory as string
    }
    this.getProducts()
  },
  methods: {
    updateRoute(resetPage = false) {
      if (resetPage) {
        this.page = '1'
      }

      // Setting query URL
      const query = {
        search: this.search,
        //page: this.page,
        orderby: this.orderBy,
        order: this.order,
        //limit: this.limit,
        structure: this.structureId,
        productType: this.productTypeFilter,
        productCategory: this.productCategoryFilter,
      }

      this.$router.push({ query: Object.assign({}, this.$route.query, query) }).catch(() => {
        // This empty catch method is here to avoid the "Avoided redundant navigation" error
      })
    },
    changePage(page: string) {
      this.page = page
      this.updateRoute()
    },
    sortChange(sort: SortObject) {
      this.order = null
      this.orderBy = null

      switch (sort.order) {
        case 'ascending':
          this.order = 'ASC'
          break

        case 'descending':
          this.order = 'DESC'
          break

        default:
          this.order = 'DESC'
          break
      }

      this.orderBy = sort.prop

      // Setting query URL
      const query = {
        search: this.search,
        //page: this.page,
        orderby: this.orderBy,
        order: this.order,
        //limit: this.limit,
        structure: this.structureId,
        productType: this.productTypeFilter,
        productCategory: this.productCategoryFilter,
      }

      this.$router.push({ query: Object.assign({}, this.$route.query, query) }).catch(() => {
        // This empty catch method is here to avoid the "Avoided redundant navigation" error
      })
    },
    getProducts() {
      if (!this.busy) {
        this.busy = true

        // Setting query URL
        this.$router
          .push({ query: Object.assign({}, this.$route.query, this.search ? { search: this.search } : null) })
          .catch(() => {
            // This empty catch method is here to avoid the "Avoided redundant navigation" error
          })

        const loading = this.$loading({
          target: '#productsTable',
          text: 'Chargement des données...',
        })

        this.$api
          .get('/products', {
            params: {
              search: this.search,
              //page: this.page,
              orderby: this.orderBy,
              order: this.order,
              //limit: this.limit,
              structure: this.structureId,
              productType: this.productTypeFilter,
              productCategory: this.productCategoryFilter,
            },
          })
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            this.products = apiResponse.data
          })
          .finally(() => {
            loading.close()
            this.busy = false
          })
      }
    },
    editProduct(product: Product) {
      if (product.id) {
        this.$router.push({
          name: 'product_edit',
          params: {
            id: product.id.toString(),
          },
        })
      }
    },
    deleteProduct(product: Product) {
      this.$confirm(
        'Êtes-vous sûr(e) de vouloir supprimer ce produit ? Attention, cette opération est irréversible.',
        'Confirmation',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Annuler',
          type: 'warning',
        }
      ).then(() => {
        this.$api
          .delete(`/product/${product.id}`)
          .then(() => {
            this.$notify({
              type: 'success',
              title: 'Succès',
              message: 'Opération réalisée avec succès !',
            })

            this.getProducts()
          })
          .catch(() => {
            this.$notify({
              type: 'error',
              title: 'Erreur',
              message: 'Une erreur est survenue. Merci de reéssayer plus tard.',
            })
          })
      })
    },
    handleFilter(value: string, row: Record<string, string | number>, column: Record<string, string>) {
      const property = column['property']

      return row[property] === value
    },
    handleStructureFilter(value: string, row: Record<string, string | number>, column: Record<string, string>) {
      const property = column['property']
      const structure = row[property] as Structure

      if (structure) {
        return structure.id?.toString() === value
      }
    },
    goToEditProduct(row: Product, column: Record<string, string>, cell: HTMLTableCellElement) {
      if (!cell.classList.contains('actions')) {
        this.$router.push({
          name: 'product_edit',
          params: {
            id: row.id?.toString() ?? '',
          },
        })
      }
    },
    getStructureName(structure: null | Structure) {
      if (structure == null) {
        return '-'
      } else {
        return structure.name
      }
    },
    formatPU(product: Product): string {
      if (product.unitPriceExcludingTaxes) {
        if (product.type == 'percent_buyer' || product.type == 'percent_seller') {
          //let formatPU = Math.round(Number.parseFloat(product.unitPriceExcludingTaxes) * 100) + " % HT"
          let formatPU = (parseFloat(product.unitPriceExcludingTaxes) * 100).toFixed(2) + ' % HT'
          return formatPU
        } else {
          let formatPU = product.unitPriceExcludingTaxes + ' € HT'
          return formatPU
        }
      } else {
        return ''
      }
    },
    getProductTaxRate(value: number, structure: null | Structure): string {
      if (structure !== null && structure.id) {
        return this.structureVatRates[structure.id]
          ? this.structureVatRates[structure.id].find((rate) => rate.value === value)?.label ?? '-'
          : '-'
      } else {
        return '-'
      }
    },
    getProductType(value: string): string {
      return this.productTypes.find((type) => type.value === value)?.label ?? '-'
    },
    getProductCategory(value: string): string {
      return this.productCategories.find((category) => category.value === value)?.label ?? '-'
    },
    getProductActive(value: number): string {
      return value ? 'Oui' : 'Non'
    },
    getStructureColor(structure: Structure) {
      return structure?.color
    },
  },
})
